import React from "react";
import Partners from "../../../images/Register.jpg";
import Bhoomitech from "./bhoomitech.png";

const PartnersComp = () => {
  return (
    <section className="partners">
      <article className="container mx-auto justify-content-center row py-12 py-md-15">
        <div className="col-12 col-md-5">
          <h2 className="display-4 mb-3">CORSNET PARTNERS</h2>
          <p>We bring solutions to make life easier for our customers.</p>
        </div>
        <div className="col-12 col-md-6">
          <div className="row justify-content-center align-items-center">
            <div className="col-8 col-lg-7 mt-8 mt-md-0">
              <img src={Partners} alt="SULECO PVT LTD" />
            </div>
            <div className="col-8 col-lg-7 mt-7 mt-md-10">
              <img src={Bhoomitech} alt="Bhoomitech" />
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default PartnersComp;
